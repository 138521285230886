import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Api from 'services/Api';
import UserBox from 'components/UserBox';
import { isLoading } from 'lib/redux-utils';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';
import User from 'modules/User';
import makeModal from 'components/Modals';
import LoadingElement from 'components/Loading/LoadingElement';
import { MagicMove } from 'components/Transitions';
import usePrevious from 'hooks/usePrevious';
import { DepositTransferablePropsNames } from './types';
import DepositForm from './DepositForm';
import ExceededSpendLimits from './components/ExceededSpendLimits';

const getTransferableProps = (props: DepositProps) =>
  Object.values(DepositTransferablePropsNames).reduce(
    (acc, prop) => ({
      ...acc,
      ...(props[prop] !== undefined ? { [prop]: props[prop] } : {})
    }),
    {}
  );

interface DepositProps {
  location: Location;
  promoCode: string;
  initialMethodRef: string;
  close: () => void;
}

const Deposit = (props: DepositProps) => {
  const { location, promoCode, initialMethodRef, close } = props;
  const dispatch = useAppDispatch();
  const paymentMethodRefs = useAppSelector(Wallet.selectors.getPaymentMethodRefs);
  const depositLimitSolicitations = useAppSelector(Wallet.selectors.getDepositLimitSolicitations);
  const failedKyc = useAppSelector(User.selectors.model).failedKyc;
  const nextAllowedDepositDate = useAppSelector(Wallet.selectors.getNextAllowedDepositDate);
  const loading = useAppSelector((state) =>
    isLoading(state, [
      Wallet.actionTypes.AT.SMS_VERIFICATION_STATE._,
      Wallet.actionTypes.AT.SUGGESTED_DEPOSIT_AMOUNTS._,
      Wallet.actionTypes.AT.LIST_PAYMENT_METHODS._,
      Wallet.actionTypes.AT.BANK_TRANSACTIONS_DEPOSIT._,
      Wallet.actionTypes.AT.AGREED_PAYMENT_TERMS._,
      Wallet.actionTypes.AT.GET_DEPOSIT_LIMIT_SOLICITATIONS._
    ])
  );
  const [screen, setScreen] = useState<string>();
  const previousLoading = usePrevious(loading);
  const search = new URLSearchParams(location.search);
  const promoCodeParam = search.get('promoCode');

  useEffect(() => {
    setScreen(nextAllowedDepositDate ? 'exceededSpendLimits' : 'depositForm');
  }, [nextAllowedDepositDate]);

  useEffect(() => {
    if (previousLoading && !loading && isEmpty(paymentMethodRefs)) {
      close();
      dispatch(Modals.actions.open('addPayment'));
    } else if (previousLoading && !loading && failedKyc) {
      close();
      dispatch(Modals.actions.open('verifyYourAccount'));
    } else if (previousLoading && !loading && depositLimitSolicitations.solicitations) {
      const transferableProps = getTransferableProps(props);
      close();
      dispatch(
        Modals.actions.open('depositLimitSolicitations', {
          depositProps: transferableProps,
          title: 'Before you deposit'
        })
      );
    }
  }, [
    loading,
    paymentMethodRefs,
    failedKyc,
    depositLimitSolicitations,
    close,
    dispatch,
    props,
    previousLoading
  ]);

  useEffect(() => {
    void Api.actions.wallet.listPaymentMethods()(dispatch);
    void Api.actions.wallet.suggestedDepositAmounts()(dispatch, true);
    void Api.actions.wallet.agreedPaymentTerms()(dispatch);
    void Api.actions.wallet.getDepositLimitSolicitations(undefined, undefined, {
      skipCache: true
    })(dispatch);
  }, [dispatch]);

  const handleComplete = (shouldPreventClose = false) => {
    void Api.actions.bingo.list({ instance: 'next' })(dispatch);
    if (!shouldPreventClose) close();
  };

  const renderChild = () => {
    switch (screen) {
      case 'exceededSpendLimits':
        return <ExceededSpendLimits onConfirm={close} />;
      case 'depositForm':
        return (
          <DepositForm
            key="DepositForm"
            onClose={close}
            onComplete={handleComplete}
            promoCode={promoCode || promoCodeParam}
            initialMethodRef={initialMethodRef}
          />
        );
      default:
        return null;
    }
  };

  return (
    <UserBox id="depositBox" className="deposit modal" title="Make a deposit" closeCallback={close}>
      <MagicMove loadingTimeout={0}>
        {loading ? <LoadingElement key="LoadingElement" /> : renderChild()}
      </MagicMove>
    </UserBox>
  );
};

export default compose(
  makeModal('deposit', { name: 'modal-fade', timeout: 200 }, { className: 'deposit modal' }),
  withRouter
)(Deposit);
