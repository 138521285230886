import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty, capitalize } from 'lodash';
import cc from 'classcat';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import reform from 'components/Reform';
import { MagicMove } from 'components/Transitions';
import Api from 'services/Api';
import Wallet from 'modules/Wallet';
import Modals from 'modules/Modals';
import User from 'modules/User';
import { paymentMethod as paymentMethodValidation } from 'lib/valFuncs';
import { logos, providerStatus, paymentMethodProvider, paymentMethodType } from 'lib/constants';
import { derivePaymentMethodLogo, deriveMethodLabel } from 'lib/paymentUtils';
import Tooltip from 'components/Tooltip';
import TrueLayerForm from 'components/TrueLayerForm';
import PaymentMethodStateLabel from 'components/PaymentMethodStatus';
import FundsProtectionCheck from 'components/AddPayment/components/FundsProtectionCheck';
import DepositOfferPromoBanner from 'components/DepositOfferPromoBanner';
import { Typography, TypographyVariant } from 'components/Typography';
import DepositBraintreeCardForm from './src/DepositBraintreeCardForm';
import DepositPaypalForm from './src/DepositPaypalForm';
import DepositMobileForm from './src/DepositMobileForm';
import DepositPaypalFormNew from './src/DepositPaypalFormNew';
import DepositEcospendForm from './src/DepositEcospendForm';
import DepositPciProxyCardForm from './src/DepositPciProxyCardForm';
import './depositForm.css';

const noop = (e) => {
  e.preventDefault();
};

export const Form = reform()(
  class _Form extends PureComponent {
    constructor(props) {
      super(props);
      this.state = { fundsProtection: false };
    }

    componentDidMount() {
      this.props.init();
    }

    componentDidUpdate(prevProps) {
      const { openAddPayment, onClose, paymentMethod } = this.props;
      if (
        prevProps.paymentMethod.value !== 'methodNewCard' &&
        paymentMethod.value === 'methodNewCard'
      ) {
        openAddPayment('card');
        onClose();
      }
      if (
        prevProps.paymentMethod.value !== 'methodNewPhone' &&
        paymentMethod.value === 'methodNewPhone'
      ) {
        openAddPayment('mobile');
        onClose();
      }
      if (
        prevProps.paymentMethod.value !== 'methodNewEcospend' &&
        paymentMethod.value === 'methodNewEcospend'
      ) {
        openAddPayment('ecospend');
        onClose();
      }
      if (
        prevProps.paymentMethod.value !== 'methodNewTruelayer' &&
        paymentMethod.value === 'methodNewTruelayer'
      ) {
        openAddPayment('truelayer');
        onClose();
      }
      if (
        prevProps.paymentMethod.value !== 'methodNewBraintree' &&
        paymentMethod.value === 'methodNewBraintree'
      ) {
        openAddPayment('braintree');
        onClose();
      }
      if (
        prevProps.paymentMethod.value !== 'methodNewPciProxy' &&
        paymentMethod.value === 'methodNewPciProxy'
      ) {
        openAddPayment('pci-proxy');
        onClose();
      }
    }

    render() {
      const {
        paymentMethod,
        cardRefs,
        bankAccountRefs,
        paymentMethods,
        paymentMethodRefs,
        hasMobile,
        hasPaypal,
        disabled,
        handleChange,
        onComplete,
        openTermsConditions,
        canAlterPrimaryMethod,
        promoCode,
        isFirstDeposit,
        isPromoCodeDisabled,
        hasAgreedPaymentTerms,
        paymentProviders,
        paymentProvidersAvailable,
        possibleNewPaymentsRefs
      } = this.props;
      const selectedValue = paymentMethod.value;
      const selectedMethod = paymentMethods?.[selectedValue];

      const newMethodMap = {
        [paymentMethodProvider.ECOSPEND]: 'methodNewEcospend',
        [paymentMethodProvider.TRUELAYER]: 'methodNewTruelayer',
        [paymentMethodProvider.PAYPAL]: 'methodPaypal',
        [paymentMethodProvider.BRAINTREE]: 'methodNewBraintree',
        [paymentMethodProvider.PCI_PROXY]: 'methodNewPciProxy',
        [paymentMethodProvider.FONIX]: 'methodNewPhone'
      };

      const fundsProtectionCheck = !hasAgreedPaymentTerms && (
        <FundsProtectionCheck
          selectedMethodType={selectedMethod?.type}
          openTermsConditions={openTermsConditions}
          checked={this.state.fundsProtection}
          onChange={() => this.setState((state) => ({ fundsProtection: !state.fundsProtection }))}
        />
      );

      const privatePaymentOptions =
        (paymentMethodRefs &&
          paymentMethodRefs.map((ref) => {
            const method = paymentMethods[ref];
            const operatorDisplay = capitalize(method.operator || method.type || 'Unknown');
            const isUnavailable = method.status === providerStatus.UNAVAILABLE;
            // Only for temporarily unavailable banks from bank providers' side (Ecospend)
            const isBankDown =
              method.type === paymentMethodType.BANK_ACCOUNT && method?.temporaryUnavailable;
            const classes = cc([
              `deposit-method deposit-method--${method.type?.toLowerCase()}`,
              isUnavailable && !isBankDown && 'unavailable'
            ]);

            return (
              <div key={method.ref} className={classes} disabled={isUnavailable && !isBankDown}>
                <img
                  className="deposit-method__icon"
                  src={derivePaymentMethodLogo(method)}
                  title={`Deposit with ${operatorDisplay}`}
                  alt={operatorDisplay}
                />
                <div>
                  <span>
                    <span>{deriveMethodLabel(method)}</span>
                  </span>
                  {isBankDown && (
                    <PaymentMethodStateLabel isSemiTransparent provider={method.provider} />
                  )}
                  {isUnavailable && !isBankDown && (
                    <PaymentMethodStateLabel provider={method.provider} />
                  )}
                </div>

                {method.requiresReverification && (
                  <span className="deposit-method__reverification">
                    <Tooltip label="Needs verification" level="warn">
                      <img src="/assets/images1/warning-icon.svg" alt="!" width={24} height={24} />
                    </Tooltip>
                  </span>
                )}
              </div>
            );
          })) ||
        [];

      let cleanNewPaymentRefs = possibleNewPaymentsRefs;
      if (cleanNewPaymentRefs) {
        if (!canAlterPrimaryMethod) {
          cleanNewPaymentRefs = cleanNewPaymentRefs.filter(
            (ref) => paymentProviders[ref].type === paymentMethodType.MOBILE
          );
        }
        if (hasMobile) {
          cleanNewPaymentRefs = cleanNewPaymentRefs.filter(
            (ref) => paymentProviders[ref].type !== paymentMethodType.MOBILE
          );
        }
        if (hasPaypal) {
          cleanNewPaymentRefs = cleanNewPaymentRefs.filter(
            (ref) => paymentProviders[ref].type !== paymentMethodType.PAYPAL
          );
        }
        if (cardRefs?.length >= 3) {
          cleanNewPaymentRefs = cleanNewPaymentRefs.filter(
            (ref) => paymentProviders[ref].type !== paymentMethodType.CARD
          );
        }
        if (bankAccountRefs.length >= 3) {
          cleanNewPaymentRefs = cleanNewPaymentRefs.filter(
            (ref) => paymentProviders[ref].type !== paymentMethodType.BANK_ACCOUNT
          );
        }
      }
      const newPaymentOptions =
        (cleanNewPaymentRefs &&
          cleanNewPaymentRefs.map((ref) => {
            const method = paymentProviders[ref];
            const isUnavailable =
              paymentProvidersAvailable[method.provider].status === providerStatus.UNAVAILABLE;
            return (
              <div
                key={newMethodMap[method.provider]}
                className={cc(['deposit-method', isUnavailable && 'unavailable'])}
                // Prop for disabling in dropdown option. Currently the Dropdown doesn't support disabling.
                // disabled={method.disabled}
                disabled={isUnavailable}
              >
                <img
                  src={method.firstDepositFields.icon || logos[method.type]}
                  alt={capitalize(method.type)}
                />
                {isUnavailable ? (
                  <div>
                    <div>{method.firstDepositFields?.label}</div>
                    <PaymentMethodStateLabel provider={method.provider} />
                  </div>
                ) : (
                  method.firstDepositFields?.label
                )}
              </div>
            );
          })) ||
        [];

      const paymentOptions = [...privatePaymentOptions, ...newPaymentOptions];
      return (
        <div id="depositForm">
          <DepositOfferPromoBanner />
          <form className="depositForm__paymentMethodPicker" onSubmit={noop}>
            <Typography variant={TypographyVariant.BodyMdStrong}>
              <div className="depositForm__label">Select Payment Method</div>
            </Typography>
            <Dropdown
              name="paymentMethod"
              disabled={disabled}
              placeholder={disabled ? 'No valid payment method found' : 'Select payment method'}
              className="dropdown--deposit"
              tabIndex={0}
              value={selectedValue}
              onChange={handleChange}
            >
              {paymentOptions}
            </Dropdown>
            {selectedMethod?.provider !== paymentMethodProvider.PCI_PROXY && (
              <div className="depositForm__separator" />
            )}
          </form>

          <MagicMove childKey={selectedValue || 'none'}>
            {selectedMethod?.type === paymentMethodType.PAYPAL ? (
              <DepositPaypalForm
                onComplete={onComplete}
                paymentMethodRef={selectedValue}
                provider={selectedMethod?.provider}
                promoCode={promoCode}
                fundsProtection={!hasAgreedPaymentTerms ? fundsProtectionCheck : null}
                disableDepositButton={!hasAgreedPaymentTerms && !this.state.fundsProtection}
                agreeDepositTerms={this.state.fundsProtection}
                // TODO: MQPV-638 refactor source of these props, they should be inside
                isPromoCodeDisabled={isPromoCodeDisabled}
                isDepositWelcomeOfferVisible={isFirstDeposit}
              />
            ) : selectedMethod?.type === paymentMethodType.MOBILE ? (
              <DepositMobileForm
                promoCode={promoCode}
                paymentMethodRef={selectedValue}
                provider={selectedMethod?.provider}
                onComplete={onComplete}
                requiresReverification={selectedMethod?.requiresReverification}
                fundsProtection={!hasAgreedPaymentTerms ? fundsProtectionCheck : null}
                disableDepositButton={!hasAgreedPaymentTerms && !this.state.fundsProtection}
                agreeDepositTerms={this.state.fundsProtection}
                isPromoCodeDisabled={isPromoCodeDisabled}
                isDepositWelcomeOfferVisible={isFirstDeposit}
              />
            ) : selectedValue === 'methodPaypal' ? (
              <DepositPaypalFormNew
                onComplete={onComplete}
                paymentMethodRef={selectedValue}
                provider="PAYPAL"
                promoCode={promoCode}
                fundsProtection={!hasAgreedPaymentTerms ? fundsProtectionCheck : null}
                disableDepositButton={!hasAgreedPaymentTerms && !this.state.fundsProtection}
                agreeDepositTerms={this.state.fundsProtection}
                isPromoCodeDisabled={isPromoCodeDisabled}
                isDepositWelcomeOfferVisible={isFirstDeposit}
              />
            ) : selectedMethod?.type === paymentMethodType.BANK_ACCOUNT &&
              selectedMethod?.provider === paymentMethodProvider.ECOSPEND ? (
              <DepositEcospendForm
                onComplete={onComplete}
                paymentMethodRef={selectedValue}
                provider={selectedMethod?.provider}
                bankLogo={selectedMethod?.bankLogo}
                identifier={selectedMethod.identifier}
                bankName={selectedMethod?.friendlyName}
                isDisabled={selectedMethod?.temporaryUnavailable}
                promoCode={promoCode}
                fundsProtection={fundsProtectionCheck}
                disableDepositButton={!hasAgreedPaymentTerms && !this.state.fundsProtection}
                agreeDepositTerms={this.state.fundsProtection}
                isPromoCodeDisabled={isPromoCodeDisabled}
                isDepositWelcomeOfferVisible={isFirstDeposit}
              />
            ) : selectedMethod?.type === paymentMethodType.BANK_ACCOUNT &&
              selectedMethod?.provider === paymentMethodProvider.TRUELAYER ? (
              <TrueLayerForm />
            ) : selectedMethod?.type === paymentMethodType.CARD &&
              selectedMethod?.provider === paymentMethodProvider.BRAINTREE ? (
              <DepositBraintreeCardForm
                disabled={!selectedValue}
                onComplete={onComplete}
                paymentMethodRef={selectedValue}
                provider={selectedMethod?.provider}
                paymentMethodBin={paymentMethod.bin}
                promoCode={promoCode}
                fundsProtection={fundsProtectionCheck}
                disableDepositButton={!hasAgreedPaymentTerms && !this.state.fundsProtection}
                agreeDepositTerms={this.state.fundsProtection}
                isPromoCodeDisabled={isPromoCodeDisabled}
                isDepositWelcomeOfferVisible={isFirstDeposit}
              />
            ) : selectedMethod?.type === paymentMethodType.CARD &&
              selectedMethod?.provider === paymentMethodProvider.PCI_PROXY ? (
              <DepositPciProxyCardForm
                disabled={!selectedValue}
                onComplete={onComplete}
                paymentMethodRef={selectedValue}
                provider={selectedMethod?.provider}
                promoCode={promoCode}
                fundsProtection={fundsProtectionCheck}
                disableDepositButton={!hasAgreedPaymentTerms && !this.state.fundsProtection}
                agreeDepositTerms={this.state.fundsProtection}
                isPromoCodeDisabled={isPromoCodeDisabled}
                isDepositWelcomeOfferVisible={isFirstDeposit}
              />
            ) : null}
          </MagicMove>

          {hasAgreedPaymentTerms && (
            <>
              <div className="toc-text">
                {selectedMethod?.type === paymentMethodType.MOBILE ? (
                  <div>Payment will be charged to your mobile bill.</div>
                ) : (
                  ''
                )}
                MrQ holds all player funds in a separate bank account in accordance with the
                Gambling Commission&apos;s basic level of protection. This means that in case of
                insolvency customer funds will not be protected.
              </div>
              <a className="toc-text underline" onClick={openTermsConditions}>
                Terms and conditions apply
              </a>
            </>
          )}
        </div>
      );
    }
  }
);

export const _DepositForm = (props) => {
  const { initialMethodRef, firstPossibleMethodRef, phoneVerified } = props;
  const fields = {
    paymentMethod: {
      initial:
        initialMethodRef ||
        firstPossibleMethodRef ||
        (phoneVerified && 'methodMobile') ||
        undefined,
      required: true,
      error: 'Please select a payment method',
      onChange: paymentMethodValidation
    }
  };

  return <Form fields={fields} submit={null} {...props} />;
};

_DepositForm.propTypes = {
  initialMethodRef: PropTypes.string,
  paymentMethodRefs: PropTypes.arrayOf(PropTypes.string),
  paymentMethods: PropTypes.object,
  phoneVerified: PropTypes.bool,
  firstPossibleMethodRef: PropTypes.string,
  cardRefs: PropTypes.array,
  bankAccountRefs: PropTypes.array,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  promoCode: PropTypes.string
};

_DepositForm.defaultProps = {
  initialMethodRef: null,
  firstPossibleMethodRef: null,
  paymentMethodRefs: [],
  paymentMethods: {},
  phoneVerified: false,
  cardRefs: [],
  bankAccountRefs: [],
  onClose: {},
  onComplete: {},
  promoCode: ''
};

const mapStateToProps = (state) => {
  const isPromoCodeDisabled = User.selectors.getIsPromoCodeDisabled(state);
  const isFirstDeposit = !Wallet.selectors.getHasDeposited(state);
  const paymentMethods = Wallet.selectors.getPaymentMethods(state);
  const paymentMethodRefs = Wallet.selectors.getDepositableMethodRefs(state);
  const paymentProviders = Wallet.selectors.getPaymentProviders(state);
  const paymentProvidersRefs = Wallet.selectors.getPaymentProvidersRefs(state);
  const paymentProvidersAvailable = Wallet.selectors.getPaymentProvidersAvailable(state);
  const paymentProvidersAvailableRefs = Wallet.selectors.getPaymentProvidersAvailableRefs(state);
  const possibleNewPaymentsRefs = Wallet.selectors.getPossibleNewPaymentsRefs(state);
  const firstPossibleMethodRef = Wallet.selectors.getFirstPossibleMethodRef(state);
  const cardRefs = Wallet.selectors.getCardRefs(state);
  const bankAccountRefs = Wallet.selectors.getBankAccountRefs(state);
  const hasCard = Wallet.selectors.hasCard(state);
  const hasMobile = Wallet.selectors.hasMobile(state);
  const hasPaypal = Wallet.selectors.hasPaypal(state);
  const hasAgreedPaymentTerms = Wallet.selectors.hasAgreedPaymentTerms(state);
  const canAlterPrimaryMethod = Wallet.selectors.canAlterPrimaryMethod(state);
  return {
    isPromoCodeDisabled,
    isFirstDeposit,
    paymentMethods,
    paymentMethodRefs,
    paymentProviders,
    paymentProvidersRefs,
    paymentProvidersAvailable,
    possibleNewPaymentsRefs,
    firstPossibleMethodRef,
    cardRefs,
    bankAccountRefs,
    hasCard,
    hasMobile,
    hasPaypal,
    canAlterPrimaryMethod,
    hasAgreedPaymentTerms,
    paymentProvidersAvailableRefs,
    disabled: isEmpty(paymentMethodRefs)
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAddPayment: (method) => dispatch(Modals.actions.open('addPayment', { method })),
  openTermsConditions: () => dispatch(Modals.actions.open('termsConditions')),
  init: () => {
    Api.actions.user.getCurrent()(dispatch);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_DepositForm);
