import { type MarketingPreferencesFormState } from './types';

export const MarketingPreferencesFormVariant = {
  BINGO: 'BINGO',
  CASINO: 'CASINO'
} as const;

export const MarketingPreferencesFormValues = {
  email: false,
  phoneCall: false,
  sms: false,
  post: false
};

export const initialMarketingPreferencesFormState: MarketingPreferencesFormState = {
  receiveAll: false,
  BINGO: MarketingPreferencesFormValues,
  CASINO: MarketingPreferencesFormValues
};
