// eslint-disable-next-line max-classes-per-file -- Legacy code, might refactor in future
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cc from 'classcat';
import Api from 'services/Api';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Auth from 'modules/Auth';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import FormError from 'components/FormError';
import { isLoading } from 'lib/redux-utils';
import { formatCurrency } from 'lib/formatters';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import { Typography, TypographyVariant } from 'components/Typography';
import {
  depositAmountPaypal as depositAmountValidation,
  MAX_DEPOSIT_PAYPAL,
  MIN_DEPOSIT
} from 'lib/valFunctors';
import DepositRange from 'components/DepositRange';
import SuggestedAmounts from 'components/SuggestedAmounts';
import { paymentMethodType } from 'lib/constants';
import 'css/components/paypal.css'; // TODO: FIX ME;
import './depositCardForm.css';

class BaseForm extends Component {
  handleAmountPresetChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  render() {
    const {
      isPromoCodeDisabled,
      fundsProtection,
      disableDepositButton,
      depositAmount,
      promotionCode,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      errorMsg,
      clientToken,
      clientTokenError,
      isDepositWelcomeOfferVisible,
      minAmount,
      maxAmount,
      onSubmit
    } = this.props;
    const buttonClass = cc([
      'paypal',
      'combo',
      'combo--horizontal',
      'combo--horizontal-right',
      'button--comboReversed',
      { 'button--loading': loading }
    ]);
    const isDepositButtonDisabled =
      disabled || !formValid || !depositAmount.valid || disableDepositButton;

    return (
      <form
        id="depositCardForm"
        name="depositPaypal"
        onSubmit={onSubmit}
        className={disabled ? 'form form--disabled' : 'form'}
      >
        <Typography variant={TypographyVariant.BodyMdStrong}>
          <div className="depositForm__label">Select Amount</div>
        </Typography>
        <SuggestedAmounts
          value={depositAmount.value}
          onChange={this.handleAmountPresetChange}
          paymentMethodType={paymentMethodType.PAYPAL}
          disabled={disabled}
        />
        <Fieldset
          field={depositAmount}
          editable={!disabled}
          inputType="number"
          min={(minAmount || MIN_DEPOSIT).toString()}
          max={(maxAmount || MAX_DEPOSIT_PAYPAL).toString()}
          step="1"
          name="depositAmount"
          className="fieldset--pound"
          labelText="Other"
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <DepositRange paymentMethodType={paymentMethodType.PAYPAL} />
        {!isPromoCodeDisabled && (
          <>
            <div className="depositForm__separator" />
            <Fieldset
              field={promotionCode}
              inputType="text"
              name="promotionCode"
              labelText="Promo code"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {isDepositWelcomeOfferVisible && promotionCode.value.length > 0 && (
              <Typography variant={TypographyVariant.BodySm}>
                <span className="promoCode__offer">
                  If this code is valid, it will override the offer above.
                </span>
              </Typography>
            )}
          </>
        )}
        {fundsProtection}
        <div className="fieldset fieldset--centerflex">
          <Button
            className={buttonClass}
            disabled={isDepositButtonDisabled}
            loading={loading}
            type="submit"
            horizontal
          >
            {cc(['Deposit', depositAmount.value && formatCurrency(depositAmount.value), 'with '])}
            <img className="buttonIcon" src="/assets/images1/paypal-text-white.svg" alt="PayPal" />
          </Button>
        </div>
        <FormError errorMessage={errorMsg || (!clientToken && clientTokenError) || undefined} />
      </form>
    );
  }
}

export const Form = reform()(BaseForm);

// eslint-disable-next-line react/no-multi-comp -- Legacy code, might refactor in future
export class _DepositPaypalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientTokenError: null,
      errorMessage: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.loadToken = this.props.requestAuthorization();
  }

  componentDidUpdate({ deviceData: prevDeviceData }) {
    const { deviceData, submitPaypal } = this.props;
    if (this.dataToSubmit && prevDeviceData !== deviceData) {
      const data = { ...this.dataToSubmit, deviceData: deviceData.data };
      submitPaypal(data).then(this.clearData).catch(this.handleError);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  submit = async (data) => {
    const { collectData } = this.props;
    // Can't trust props async
    try {
      const { data: token } = await this.loadToken;
      this.dataToSubmit = data;
      if (this.mounted) {
        this.setState({ clientTokenError: null });
      }
      collectData(token);
    } catch (e) {
      if (this.mounted) {
        this.setState({
          clientTokenError:
            'There was a problem contacting PayPal. Please try another payment option.'
        });
      }
    }
  };

  handleError = (error) => {
    this.setState({ errorMessage: error.message });
    this.clearData();
  };

  clearData = () => {
    this.dataToSubmit = undefined;
  };

  render() {
    const fields = {
      depositAmount: {
        initial: this.props.defaultAmount || 50,
        required: true,
        error: `Minimum amount ${formatCurrency(10)}`,
        onChange: depositAmountValidation(this.props.minAmount, this.props.maxAmount)
      },
      promotionCode: {
        initial: this.props.promoCode || '',
        required: false,
        onChange: promotionCodeValidation,
        error: "This doesn't look like a valid code"
      }
    };
    const { clientTokenError, errorMessage } = this.state;
    const { clientToken } = this.props;

    // TODO: No spread props
    return (
      <Form
        fields={fields}
        clientToken={clientToken}
        clientTokenError={clientTokenError}
        errorMsg={errorMessage}
        submit={this.submit}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, { provider }) => ({
  deviceData: state.Wallet.deviceData,
  clientToken: state.Wallet.paypalToken,
  defaultAmount:
    provider &&
    Wallet.selectors.getDefaultDepositAmountForPaymentType(state, paymentMethodType.PAYPAL),
  minAmount:
    provider && Wallet.selectors.getMinAmountForPaymentType(state, paymentMethodType.PAYPAL),
  maxAmount:
    provider && Wallet.selectors.getMaxAmountForPaymentType(state, paymentMethodType.PAYPAL),
  loading: isLoading(state, [
    Auth.AT.FINGERPRINT._,
    Wallet.actionTypes.AT.DEPOSIT_PAYPAL._,
    Wallet.actionTypes.AT.COLLECT_DATA._,
    Wallet.actionTypes.AT.PAYPAL_TOKEN._
  ])
});

const mapDispatchToProps = (dispatch, { onComplete, paymentMethodRef, agreeDepositTerms }) => ({
  collectData: (clientToken) => dispatch(Wallet.actions.collectData(clientToken)),
  requestAuthorization: () => Api.actions.wallet.paypalToken()(dispatch),
  submitPaypal: async (data) => {
    const { depositAmount, promotionCode, deviceData } = data;
    const amount = depositAmount;
    const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
    const result = await Api.actions.wallet.depositPaypal(null, {
      agreeDepositTerms,
      paymentMethodRef,
      amount,
      promotionCode,
      deviceData,
      deviceInfo
    })(dispatch, true);
    onComplete(true);
    return result;
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_DepositPaypalForm);
