import React, { Component } from 'react';
import { connect } from 'react-redux';
import cc from 'classcat';
import Api from 'services/Api';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Auth from 'modules/Auth';
import Wallet from 'modules/Wallet';
import Button from 'components/Button';
import { isLoading } from 'lib/redux-utils';
import { formatCurrency } from 'lib/formatters';
import Modals from 'modules/Modals';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import { Typography, TypographyVariant } from 'components/Typography';
import {
  depositAmountMobile as depositAmountValidation,
  MAX_DEPOSIT_MOBILE,
  MIN_DEPOSIT
} from 'lib/valFunctors';
import { paymentMethodType } from 'lib/constants';
import DepositRange from 'components/DepositRange';
import SuggestedAmounts from 'components/SuggestedAmounts';
import './depositCardForm.css';

class BaseForm extends Component {
  handleAmountPresetChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  render() {
    const {
      isPromoCodeDisabled,
      depositAmount,
      promotionCode,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      minAmount,
      maxAmount,
      onSubmit,
      fundsProtection,
      disableDepositButton,
      isDepositWelcomeOfferVisible,
      requiresReverification
    } = this.props;
    const buttonClass = cc([{ 'button--loading': loading }]);
    const buttonText = cc([
      requiresReverification && 'Verify & ',
      'Deposit',
      depositAmount.value && formatCurrency(depositAmount.value)
    ]);
    const isDepositButtonDisabled =
      disabled || !formValid || !depositAmount.valid || disableDepositButton;

    return (
      <form
        id="depositCardForm"
        name="depositMobile"
        onSubmit={onSubmit}
        className={disabled ? 'form form--disabled' : 'form'}
      >
        <Typography variant={TypographyVariant.BodyMdStrong}>
          <div className="depositForm__label">Select Amount</div>
        </Typography>
        <SuggestedAmounts
          value={depositAmount.value}
          onChange={this.handleAmountPresetChange}
          paymentMethodType={paymentMethodType.MOBILE}
          disabled={disabled}
        />
        <Fieldset
          field={depositAmount}
          editable={!disabled}
          inputType="number"
          min={(minAmount || MIN_DEPOSIT).toString()}
          max={(maxAmount || MAX_DEPOSIT_MOBILE).toString()}
          step="1"
          name="depositAmount"
          className="fieldset--pound"
          labelText="Other"
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <DepositRange paymentMethodType={paymentMethodType.MOBILE} />
        {!isPromoCodeDisabled && (
          <>
            <div className="depositForm__separator" />
            <Fieldset
              field={promotionCode}
              inputType="text"
              name="promotionCode"
              labelText="Promo code"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {isDepositWelcomeOfferVisible && promotionCode.value.length > 0 && (
              <Typography variant={TypographyVariant.BodySm}>
                <span className="promoCode__offer">
                  If this code is valid, it will override the offer above.
                </span>
              </Typography>
            )}
          </>
        )}
        {fundsProtection}
        <div className="fieldset fieldset--centerflex">
          <Button
            className={buttonClass}
            disabled={isDepositButtonDisabled}
            loading={loading}
            type="submit"
            buttonText={buttonText}
          />
        </div>
      </form>
    );
  }
}

export const Form = reform()(BaseForm);

// eslint-disable-next-line react/no-multi-comp -- Legacy
export const _DepositMobileForm = (props) => {
  const fields = {
    depositAmount: {
      initial: props.defaultAmount || 30,
      required: true,
      error: `Minimum amount ${formatCurrency(10)}`,
      onChange: depositAmountValidation(props.minAmount, props.maxAmount)
    },
    promotionCode: {
      initial: props.promoCode || '',
      required: false,
      onChange: promotionCodeValidation,
      error: "This doesn't look like a valid code"
    }
  };

  return <Form fields={fields} {...props} />;
};

const mapStateToProps = (state, { provider }) => ({
  defaultAmount:
    provider &&
    Wallet.selectors.getDefaultDepositAmountForPaymentType(state, paymentMethodType.MOBILE),
  minAmount:
    provider && Wallet.selectors.getMinAmountForPaymentType(state, paymentMethodType.MOBILE),
  maxAmount:
    provider && Wallet.selectors.getMaxAmountForPaymentType(state, paymentMethodType.MOBILE),
  loading: isLoading(state, [Auth.AT.FINGERPRINT._, Wallet.actionTypes.AT.DEPOSIT_MOBILE._])
});

const mapDispatchToProps = (
  dispatch,
  { onComplete, paymentMethodRef, requiresReverification, agreeDepositTerms }
) => ({
  submit: async (data) => {
    const { depositAmount, promotionCode } = data;
    if (requiresReverification) {
      dispatch(Modals.actions.close('deposit'));
      dispatch(
        Modals.actions.open('verifyDepositMobile', {
          depositAmount,
          promotionCode
        })
      );
      return false;
    } else {
      const amount = depositAmount;
      const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
      const result = await Api.actions.wallet.depositMobile(null, {
        paymentMethodRef,
        amount,
        promotionCode,
        deviceInfo,
        agreeDepositTerms
      })(dispatch);
      onComplete(true);
      return result;
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_DepositMobileForm);
